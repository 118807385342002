import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Spinner from '../assets/icons/spinnericon.inline.svg';
import SpinnerWhite from '../assets/icons/spinner-white.inline.svg';

const Loading = ({ className, white, viewBox }) => {
  const SpinnerComponent = white ? SpinnerWhite : Spinner;

  return (
    <div className={clsx('loader', className)}>
      <SpinnerComponent
        className={clsx(className, {
          'fill-primary': !white,
        })}
        viewBox={viewBox}
        alt="Loading"
        aria-hidden="true"
      />
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  viewBox: PropTypes.string,
  white: PropTypes.bool,
};

export default Loading;
